import axios from 'axios';
import router from '../router'
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
export class TokenStorage {

  static LOCAL_STORAGE_TOKEN = 'token';
  static LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

  static isAuthenticated() {
    return this.getToken() !== null;
  }

  static getAuthentication() {
    return {
      headers: { 'Authorization': 'Bearer ' + this.getToken() }
    };
  }

  static getNewToken() {
    return new Promise((resolve, reject) => {
      axios
        .post('/refresh', {
          refresh_token: this.getRefreshToken()
        })
        .then(response => {

          this.storeToken(response.data.access_token);
          this.storeRefreshToken(response.data.refresh_token);

          resolve(response.data.token);
        })
        .catch((error) => {
          if (error.response.data.trace === "Token is expired") {
            this.clear()
            router.replace({name: 'Login'})
          }
          reject(error);
        });
    });
  }

  static storeToken(token) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  static storeRefreshToken(refreshToken) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  }

  static clear(){
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  static getRefreshToken() {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  static getToken() {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }
}
