import Vue from 'vue'
import VueRouter from 'vue-router'
import Wrapper from '../layouts/Wrapper'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkname: "login" */ '../views/Login.vue')
  },
  {
    path: '/',
    redirect: 'reports',
    name: 'Wrapper',
    component: Wrapper,
    children: [
      {
        path: '/reports/:id?',
        name: 'Reports',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Reports.vue'),
      },
      {
        //route to hardware component

        path: '/hardware/:tab/summary',
        name: 'Hardware',
        component: () => import(/* webpackChunkName: "hardware" */ '../views/Hardware.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
