<template>
  <v-navigation-drawer
      app
      color="blackish"
      width="100"
      dark
      class="nav-drawer"
      mobile-breakpoint="600"
  >
    <div class="d-none d-sm-flex justify-center ma-4">
      <router-link
          style="height: 48px;"
          to="/reports">
        <img
            height="48"
            src="../assets/logo.png"
            alt="logo"/>
      </router-link>
    </div>
    <v-list class="pa-0">
      <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          router
          class="nav-list"
          :class="$route.name === 'locations' || $route.name === 'location' ? 'bg-color' : null"
          :to="item.route"
          active-class="bg-color"
      >
        <v-list-item-content class="py-2">
          <v-icon class="mb-2">
            {{ item.icon }}
          </v-icon>
          <v-list-item-title class="caption font-weight-medium text-center">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Reports",
          icon: 'mdi-file-document',
          route: '/reports'
        },
        {
          title: "Hardware",
          icon: 'mdi-memory',
          route: '/hardware/cpu/summary'
        },
      ],
    };
  },
};
</script>

<style
    lang="scss"
    scoped>
.nav-list {
  height: 64px;
}
.csv-content{
  position: absolute;
  bottom: 0;
  margin-left: 22px;
  margin-bottom: 20px;
  width: 100%;
}
.bg-color {
  background-color: rgba(255, 255, 255, 0.08);
}

.v-navigation-drawer {
  z-index: 999;
}

::v-deep {
  .v-list-item--active::before {
    display: none;
  }
}

.nav-drawer {
  z-index: 900;
}
</style>
