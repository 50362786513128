import Api from "../../services/Api";


const state = {
    hardware_data: false,
    current_hardware: "",
    date_interval: "",
    route_name : 'cpu',
    hardware: [],
    data_hardware: {},
    related_reports: [],
    series: [],
    chartOptions: {
      colors: ['#2B85D8', '#ff0000' ],
      chart: {
        width: 280,
        type: 'pie',
      },
      labels: ['Passed', 'Failed'],
      responsive: [{
        breakpoint: 380,
        options: {
          chart: {
            width: 100
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
}
const getters = {
    getHardware(state) {
        return state.hardware;
    },
    getRoute(state){
        return state.route_name;
    },
    getHardwareData(state) {
        return state.hardware_data;
    },
    getCurrentHardware(state){
        return state.current_hardware;
    },
    getDataHardware(state){
        return state.data_hardware;
    },
    getSeries(state){
        return state.series;
    },
    getChartOptions(state){
        return state.chartOptions;
    },
    getRelatedReports(state){
        return state.related_reports;
    }
  
}
const mutations = {
    SET_HARDWARE(state, data) {
        state.hardware = data;
    },
    SET_ROUTE_NAME(state, data) {
        state.route_name = data;
    },
    SET_HARDWARE_DATA(state, data) {
        state.hardware_data = data;
    },
    SET_CURRENT_HARDWARE(state, data) {
        state.current_hardware = data;
    },
    SET_DATA_HARDWARE(state, data) {
        state.data_hardware = data;
    }, 
    SetSeries(state, data) {
        state.series = data;
    },
    SetRelatedReports(state, data) {
        state.related_reports = data;
    },
    SetDateInterval(state, data) {
        state.date_interval = data;
    }
    
}
const actions = {
    getHardware({commit}) {
        return new Promise((resolve) => {
            Api.getHardware(state.route_name).then(response => {
                console.log(response)
                commit('SET_HARDWARE', response.data);
                console.log(response.data);
                resolve();
            }).catch(err => {
                console.log(err);
            }).finally(() => {
             console.log('finally');   
            });
        });
    },
    getCurrentHardware({commit}) {
        return new Promise((resolve) => {
            Api.getCurrentHardware(state.route_name, state.current_hardware).then(response => {
                commit('SET_DATA_HARDWARE', response.data);
                commit('SetSeries', [response.data.device_details.overall_passed, response.data.device_details.overall_failed]);
                console.log(response.data.device_details);
                resolve();
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                
            });
        });
    },
    getRelatedReports({commit}) {
        return new Promise((resolve) => {
            Api.getCurrentHardwarePeriod(state.route_name, state.current_hardware, state.date_interval).then(response => {
                commit('SetRelatedReports', response.data.related_reports);
                console.log(response.data);
                resolve();
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

