<template>
  <div>
    <v-row>
      <v-tabs background-color="white" height="64px" style="border-bottom: 1px solid #dedede;" class="tab-container">
          <span class="capitalize Text-Style-4 mt-6 ml-4">
          {{ this.$route.name }}
          </span>
          <v-btn @click="downloadReport()" v-if="this.$route.name === 'Reports'" small color="primary" class="button-align-right mt-6"><v-icon class="mr-2">mdi-file-download-outline</v-icon><span>Export CSV</span></v-btn>
      </v-tabs>
    </v-row>
<!--    <v-divider class="mx-1" style="margin-top: -1px"/>-->
    <router-view/>
  </div>
</template>
<script>
import Api from '../services/Api';
export default {
  name: "Wrapper",
  methods: {
    downloadReport() {
      Api.downloadCSV();
    }
  },
};
</script>
<style lang="scss" scoped>
.device-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  border-radius: 4px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-button {
  margin-right: 64px;
}

.location-card {
  transition: all 0.1s ease !important;
}

.location-card:hover {
  background-color: var(--v-general_bckgr-base, 0.3) !important;
}
.button-align-right {
  margin-left: auto;
  margin-right: 16px;
}
.location-info {
  margin-left: 16px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column !important;
}

.v-card__title {
  display: flex;
  flex-direction: row;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
}

.selected-tab {
  color: var(--v-textColor-base) !important;
}

.capitalize {
  text-transform: capitalize;

}

.test {
  width: 100%;
}

.tab-container {
  font-family: Roboto,serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--v-secondaryText-base);
}

.Text-Style-4 {
  color: var(--v-textColor-base) !important;
}


::v-deep {
  .v-tab {
    letter-spacing: normal;
  }
}
</style>
