import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/services/Api'
import { TokenStorage } from '@/services/TokenStorage';
Vue.use(Vuex)

const state = {
  user: {

  },
  loading: false,
};

const getters = {
  loading(state) {
    return state.loading
  },
};
const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      Api.login(data).then(response => {
        console.log(response.data);
        TokenStorage.storeToken(response.data.access_token)
        TokenStorage.storeRefreshToken(response.data.refresh_token)
        commit('SET_LOADING', false);
        resolve();
      }).catch(err => {
        commit('SET_LOADING', false);
        reject(err);
      });
    });
  },
};


const mutations = {
  SET_LOADING(state, data) {
    state.loading = data;
  },
};

export default {

  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
