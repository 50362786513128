import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2b85d8',
        general_bckgr: '#ffffff',
        secondaryText: '#7a7a7a',
        error: '#ec4234',
        success: '#00a86b',
        warning: '#ffba13',
        background: '#f8f8f8',
        lightGrey: '#e4e5e6',
        blackish: '#232c33',
        lineColor: '#dedede',
        textColor: '#222222',
        overlaycolor: '#2c3039',
        greyAlert: '#757a83',
        blueishGray: '#103456',
        babyBlueish: '#95c2eb',
        lightRed: '#f9585a',
        darkerPink: '#b40d85',
        lightAstral: '#357e9d',
        bgIconMatisse: '#204998',
        bgIconbourbon: '#c17720',
        bgIcontTuatara: '#373733',
        crtBarJade: '#00a86b',
        crtBarCinnabar: '#ec4234',
        bgPillowOlivine: '#93c47d',
        bgPillowAlto: '#d3d3d3',
        bourbonLight: '#ecc859',
        bourbonLighter: '#e2b32b',
        pistachio: '#99cc00',
        rectBgd: '#f4f4f4',
        notInstalledText: '#8A8A8A'
      },
    },
  },
});
