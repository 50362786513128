import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"nav-drawer",attrs:{"app":"","color":"blackish","width":"100","dark":"","mobile-breakpoint":"600"}},[_c('div',{staticClass:"d-none d-sm-flex justify-center ma-4"},[_c('router-link',{staticStyle:{"height":"48px"},attrs:{"to":"/reports"}},[_c('img',{attrs:{"height":"48","src":require("../assets/logo.png"),"alt":"logo"}})])],1),_c(VList,{staticClass:"pa-0"},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,staticClass:"nav-list",class:_vm.$route.name === 'locations' || _vm.$route.name === 'location' ? 'bg-color' : null,attrs:{"link":"","router":"","to":item.route,"active-class":"bg-color"}},[_c(VListItemContent,{staticClass:"py-2"},[_c(VIcon,{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c(VListItemTitle,{staticClass:"caption font-weight-medium text-center"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }