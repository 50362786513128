<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <template-default/>
  </v-app>
</template>

<script>
import TemplateDefault from "./layouts/TemplateDefault";


export default {
  name: 'App',
  components: {
    TemplateDefault,
  },
  data: () => ({
    //
  }),
};
</script>
<style>
  html { 
    overflow: hidden !important; 
    overflow: auto
    }
  
</style>