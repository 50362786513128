import Vue from 'vue'
import Vuex from 'vuex'

import user from "./modules/user.store";
import report from "./modules/report.store";
import hardware from "./modules/hardware.store";
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    report,
    hardware
  }
})
